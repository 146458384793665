var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.signupConfigurationInfo ? 'infoCard' : 'login-form'
  }, [_c('div', {
    staticClass: "pt-lg-0 pt-5"
  }, [_c('h2', {
    staticClass: "bold-text darkGrey--text"
  }, [_vm._v("Welcome to TestApp.io")]), _c('span', {
    staticClass: "darkGrey--text font-size-h4"
  }, [_vm._v(" Already have an account? "), _c('router-link', {
    staticClass: "primary--text text-decoration-underline bold-text mx-1",
    attrs: {
      "to": {
        name: 'signin'
      }
    }
  }, [_vm._v("Signin")])], 1)]), _c('v-card', {
    staticClass: "transparent mt-15",
    attrs: {
      "disabled": _vm.busy,
      "flat": "",
      "tile": ""
    }
  }, [_vm.signupConfigurationInfo ? _c('v-card', {
    staticClass: "px-4 py-4"
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "bodyFont mb-3"
  }, [_vm._v("TestApp.io is for")]), _vm._l(_vm.testAppContain, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "smallFont"
    }, [_c('v-icon', {
      attrs: {
        "color": "success"
      },
      domProps: {
        "textContent": _vm._s('mdi-check')
      }
    }), _c('span', {
      domProps: {
        "innerHTML": _vm._s(item)
      }
    })], 1);
  })], 2), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-divider', {
    staticClass: "mb-8"
  }), _c('div', {
    staticClass: "bodyFont mb-3"
  }, [_vm._v(" TestApp is "), _c('b', [_c('u', [_vm._v("not")])])]), _vm._l(_vm.testAppNotContain, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "smallFont"
    }, [_c('v-icon', {
      attrs: {
        "color": "error"
      },
      domProps: {
        "textContent": _vm._s('mdi-close')
      }
    }), _c('span', {
      domProps: {
        "innerHTML": _vm._s(item)
      }
    })], 1);
  })], 2)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "depressed": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goHome();
      }
    }
  }, [_vm._v(" Learn more ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": function click($event) {
        _vm.signupConfigurationInfo = false;
      }
    }
  }, [_vm._v(" Got it, let me to signup! ")])], 1)], 1) : _c('signup-form', {
    attrs: {
      "default-signup": ""
    },
    on: {
      "error": _vm.setErrorMessage
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }