<template>
  <div :class="signupConfigurationInfo ? 'infoCard' : 'login-form'">
    <div class="pt-lg-0 pt-5">
      <h2 class="bold-text darkGrey--text">Welcome to TestApp.io</h2>
      <span class="darkGrey--text font-size-h4">
        Already have an account?
        <router-link
          :to="{ name: 'signin' }"
          class="primary--text text-decoration-underline bold-text mx-1"
          >Signin</router-link
        >
      </span>
    </div>
    <!--  -->
    <v-card :disabled="busy" class="transparent mt-15" flat tile>
      <v-card v-if="signupConfigurationInfo" class="px-4 py-4">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <div class="bodyFont mb-3">TestApp.io is for</div>
              <div
                v-for="(item, index) in testAppContain"
                :key="index"
                class="smallFont"
              >
                <v-icon color="success" v-text="'mdi-check'"></v-icon>
                <span v-html="item"> </span>
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <v-divider class="mb-8"></v-divider>

              <div class="bodyFont mb-3">
                TestApp is <b><u>not</u></b>
              </div>
              <div
                v-for="(item, index) in testAppNotContain"
                :key="index"
                class="smallFont"
              >
                <v-icon color="error" v-text="'mdi-close'"></v-icon>
                <span v-html="item"> </span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="text-transform-none"
            color="primary"
            depressed
            outlined
            @click="goHome()"
          >
            Learn more
          </v-btn>
          <v-btn
            :outlined="$vuetify.theme.dark"
            class="text-transform-none"
            color="primary"
            depressed
            @click="signupConfigurationInfo = false"
          >
            Got it, let me to signup!
          </v-btn>
        </v-card-actions>
      </v-card>
      <signup-form v-else default-signup @error="setErrorMessage"></signup-form>
    </v-card>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import SignupForm from "@/view/components/User/SignupForm";
import { mapMutations } from "vuex";

export default {
  components: { SignupForm },
  mixins: [veeValidate],
  name: "signup",
  data() {
    return {
      signupConfigurationInfo: false,
      errorMessage: "",
      testAppContain: [
        "Sharing your app for testing and feedback",
        "Sharing your app privately within your team and externally with anyone",
        "Collecting feedback from your testers before publishing to stores",
        "Uploading (apk or aab) for Android",
        "Uploading <b>ad-hoc IPA</b> for iOS",
        "Creating a single public install page for both Android & iOS (IPA or TestFlight)",
        "And many more...",
      ],
      testAppNotContain: [
        "A replacement for Apple store",
        "A replacement for Google Play store",
        "For distributing harmful apps",
      ],
      busy: false,
    };
  },

  created() {
    if (
      this.$route.query &&
      this.$route.query.plan &&
      ["starter", "pro"].includes(this.$route.query.plan)
    ) {
      window.sessionStorage.setItem("plan_redirection", this.$route.query.plan);
      window.sessionStorage.setItem("plan_billing", this.$route.query.billing);
      this.setRedirectedFromPlans(this.$route.query.plan);
    }
  },
  metaInfo() {
    let title = "";
    if (this && this.$route.query.plan === "starter") {
      title = "Starter";
    } else if (this && this.$route.query.plan === "pro") {
      title = "Pro";
    } else if (this && this.$route.query.plan === "freelancer") {
      title = "Freelancer";
    }
    return {
      title: `${
        this && this.$route.query && this.$route.query.plan
          ? "TestApp.io - Portal - " + title + " Plan"
          : "TestApp.io - Portal - Signup"
      }`,
    };
  },
  methods: {
    ...mapMutations({
      setRedirectedFromPlans: "setRedirectedFromPlans",
    }),
    setErrorMessage(message) {
      this.errorMessage = message;
    },
    goHome() {
      window.open("https://testapp.io?learn_more", "_self");
    },
  },
};
</script>
<style scoped>
.infoCard {
  width: 100%;
  max-width: 600px;
}
</style>